/** @format */

import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import Form from "./Form";
import { Helmet } from "react-helmet";

const HomeLoan = () => {
  var root = document.getElementsByTagName("html")[0];
  if (document.body) {
    root.setAttribute("class", "fonts100");
  }
  return (
    <React.Fragment>
      <Helmet>
        <title>Easy Loan Approval</title>
      </Helmet>
      <div className="easycarloan">
        <Header />
        <main className={`easycarloan__main`}>
          <Form />
        </main>
        <Footer />
      </div>
    </React.Fragment>
  );
};

export default HomeLoan;
