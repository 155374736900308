/** @format */

import React from "react"
import { Row, Col } from "react-bootstrap"
import Accordion from "react-bootstrap/Accordion"
import { LazyLoadImage } from "react-lazy-load-image-component"
import bankLogo from "../assets/images/logo-list.png"
import bankLogoMb from "../assets/images/logo-list-mb.png"
import bannerHr from "../assets/images/banner-header.jpg"
import box1 from "../assets/images/get-o-quote.png"
import box2 from "../assets/images/find-a-card.png"
import box3 from "../assets/images/pick.png"

const Form = () => {
  return (
    <section className="form-content">
      <div className="wrap-form">
        <div className="container">
          <h1 className="title-header">Same-Day Business Loan Approvals</h1>
          <div className="sub-header text-center">
            <p>Receive Funds Into Your Bank Account Within 24 Hours!</p>
          </div>
          <div className="hr-banner">
            <LazyLoadImage src={bannerHr} alt="Banner" width="100%" />
          </div>
          <div className="text-center wrapper-button">
            <a
              href="https://business.easyloanapproval.com.au/"
              className="button-link"
              target="_blank"
              rel="noreferrer"
            >
              APPLY NOW
            </a>
          </div>
        </div>
      </div>
      <div className="home-page">
        <div className="container">
          <h2 className="title light-line">
            Up To $500,000 Dollars in Business Funding
          </h2>
          <Row>
            <Col xs={12} md={4}>
              <div className="item">
                <LazyLoadImage src={box1} alt="" width="130" />
                <h2>Simple Enquiry</h2>
                <p>
                  Complete our easy 60 second quiz to help us identify the best
                  way to help you with your loan.
                </p>
              </div>
            </Col>
            <Col xs={12} md={4}>
              <div className="item">
                <LazyLoadImage src={box2} alt="" width="90" />
                <h2>Choose Your Loan</h2>
                <p>
                  One of our specialized partners will assist in finding you the
                  most competitive loan option for your needs.
                </p>
              </div>
            </Col>
            <Col xs={12} md={4}>
              <div className="item">
                <LazyLoadImage src={box3} alt="" width="85" className="mb-2" />
                <h2>Funds In Your Account</h2>
                <p>
                  We can get your approved in as little as 3 hours and in some
                  cases funding same day or within 24hrs. We are your go-to
                  solution for connecting with competitive lenders with minimal
                  red tape!
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div className="container text-left">
        <div className="bankLogo">
          <LazyLoadImage
            src={bankLogo}
            className="d-none d-md-block"
            alt="bankLogo"
            width="100%"
          />
          <LazyLoadImage
            src={bankLogoMb}
            className="d-md-none"
            alt="bankLogo"
            width="100%"
          />
        </div>

        <h2 className="title light-line">Frequently asked questions</h2>
        <Accordion>
          <Accordion.Item eventKey="0">
            <Accordion.Header>How much funding do you offer?</Accordion.Header>
            <Accordion.Body>
              <p>
                Our lenders can offer personalised financing solutions to
                support your business’s growth and cash flow needs, up to $5m.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              How long does the approval process take?
            </Accordion.Header>
            <Accordion.Body>
              <p>
                We haven't seen one identical situation yet, so it depends on a
                few factors. But If you have a straight forward funding need and
                the right information on hand, Our lenders can get you approved
                in as little as 3 hours, plus a dedicated loan specialist who
                will assist throughout your journey.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>
              What loan amounts are available?
            </Accordion.Header>
            <Accordion.Body>
              <p>
                Most of our lenders offer funding options up to $5 million
                dollars.we have established minimum eligibility criteria to
                ensure that our business loans are accessible to a broad range
                of businesses. Each loan has different eligibility criteria.
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>
              What are the eligibility requirements?
            </Accordion.Header>
            <Accordion.Body>
              <p>
                <ol>
                  <li>
                    An active Australian Business Number (ABN). This
                    demonstrates that your business is operating legally in
                    Australia.
                  </li>
                  <li>
                    At least 4 months of trading. This helps us assess your
                    business’s stability and potential for growth.
                  </li>
                  <li>
                    A monthly revenue of at least $12,000. This criterion helps
                    us determine how much we can lend you, as well as your
                    ability to manage and repay the loan.
                  </li>
                </ol>
              </p>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4">
            <Accordion.Header>How do I get started?</Accordion.Header>
            <Accordion.Body>
              <p>
                Getting started is easy. Click the Apply Now button below and
                start the form. It will guide you through and we will then reach
                out and present you your options. It’s free of charge and
                obligation.
              </p>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </div>
      <div className="pt-4 mt-md-4">
        <div className="text-center wrapper-button">
          <a
            href="https://business.easyloanapproval.com.au/"
            className="button-link"
            target="_blank"
            rel="noreferrer"
          >
            APPLY NOW
          </a>
        </div>
      </div>
    </section>
  )
}

export default Form
