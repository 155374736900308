/** @format */

import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Row, Col } from "react-bootstrap";
import imgLogo from "../assets/images/logo.png";
import imgLock from "../assets/images/logo-secure.png";
import imgRating from "../assets/images/list-star.png";

const Header = () => {
  return (
    <header className="page-refinance-fb header pb-0">
      <div className="header-wrap" id="header">
      <div className="container">
        <Row className="d-flex box m-0 justify-content-between align-items-center">
          <Col xs={8} md={4} className="align-self-center logo group-logo">
            <div className="logo">
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a className="logo__img" href="/">
                <LazyLoadImage
                  src={imgLogo}
                  alt="logo"
                  width="100%"
                  height="auto"
                />
              </a>
            </div>
          </Col>
          <Col
            xs={4}
            md={4}
            className="align-self-center logo secure d-flex justify-content-end align-items-center"
          >
            <div>
              <div>
                <img src={imgLock} alt="This is a Safe & Secure website" />
              </div>
              <div className="wrapper-rating">
                <img src={imgRating} alt="Rating" />
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
    </header>
  );
};

export default Header;
